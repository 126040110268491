import React from 'react';
import { Text, TextVariant } from '@naf/text';
import { Grid, GridCol, GridRow } from '@naf/grid';
import { TextListItem } from '@naf/text-list';
import { BenefitsType } from '../../../../../types/benefitsType';
import { useWindowLocation } from '../../../hooks/Window/useWindowLocation';
import useSelector from '../../../redux/typedHooks';
import { useAuth0Token } from '../../../hooks/useAuth0Token';
import { useDocument } from '../../../hooks/useDocument';
import { LoaderContent } from '../../LoaderPage';
import SchemaArticle from '../../../components/structured-data/SchemaArticle';
import { useSendGTMEventOnce } from '../../../hooks/GTM/useSendGTMEventOnce';
import BreadCrumb from '../../../components/breadcrumb/BreadCrumb';
import StA from '../../../styles/articles/StyledArticle';
import BlockContent from '../../../components/block-content/BlockContent';
import FeedbackSurvey from '../../../components/feedbacksurvey/FeedbackSurvey';
import * as S from '../../BenefitArticle/Styles';
import { MetaData } from '../../../components/metaData/MetaData';
import { InternalSearchCardType } from '../../../components/layout/InternalSearchMetaData';
import { LayoutWithMainContent } from '../../../components/layout/Layout';
import RelatedBenefits from '../../BenefitArticle/RelatedBenefits';
import { NotFound } from '../../../loadable-elements/NotFound';

type Props = {
  match: {
    params: { slug: string };
  };
};

export const LocalBenefitArticle = ({
  match: {
    params: { slug },
  },
}: Props) => {
  const windowPath = useWindowLocation();
  const { simpleToken } = useAuth0Token();
  const {
    data,
    meta: { isUpdating },
  } = useDocument(slug, simpleToken);
  const url = useWindowLocation();
  const categoriesState = useSelector((state) => state.sitestructure);
  const benefitData = data as BenefitsType;
  const mappedCategory = benefitData?.category?.slug
    ? categoriesState?.mappedCategories[benefitData?.category?.slug].data
    : null;
  useSendGTMEventOnce(
    benefitData && {
      page_type: 'LocalBenefitPage',
      pageCategory: `${mappedCategory?.prettyUrl}/${benefitData.title}`,
      contentId: benefitData.id,
    },
    [benefitData],
  );

  if (!data) {
    return isUpdating ? <LoaderContent /> : <NotFound />;
  }

  const {
    title,
    ingress,
    meta,
    body,
    id,
    video,
    localBenefitImage,
    relatedContent,
    seoConfig,
    partner,
    keyInformation,
    metaDisplay,
  } = benefitData;

  const image = localBenefitImage?.url;

  if (!!id || typeof id !== 'undefined') {
    return (
      <LayoutWithMainContent
        title={title}
        description={seoConfig?.introduction || ingress}
        isHiddenFromSearch={seoConfig?.isHiddenFromSearch}
        seoDescription={seoConfig?.introduction || undefined}
        seoTitle={seoConfig?.title || undefined}
        imgUrl={localBenefitImage?.url}
        internalSearchMetaData={{
          cardType: InternalSearchCardType.LocalBenefit,
          imageUrl: localBenefitImage?.url,
          partnerLogoUrl: partner?.logo?.srcSet?.jpgLarge,
          partnerName: partner?.partnerName,
        }}
        url={url}
      >
        <SchemaArticle
          title={title}
          description={ingress}
          imgUrl={image}
          datePublished={meta?.publishAt}
          dateModified={meta?.updatedAt}
          url={url}
          id={slug}
        />
        <Grid>
          <GridRow>
            <GridCol s="12" m="12" l="12" xl="12">
              <BreadCrumb />
              <GridRow>
                <GridCol s="12" m="12" l="8" xl="8">
                  {image && !video && (
                    <S.ImageWrapper>
                      <S.ImageFigure>
                        <img src={image} alt={localBenefitImage?.caption || ''} />
                      </S.ImageFigure>
                      {partner?.logo && (
                        <S.PartnerlogoWrapper>
                          <img src={partner.logo.srcSet?.jpgLarge} alt={partner.logo?.source_altText || ''} />
                        </S.PartnerlogoWrapper>
                      )}
                    </S.ImageWrapper>
                  )}
                  {title && (
                    <S.StyledHeaderText tag="h1" variant={TextVariant.Header1}>
                      {title}
                    </S.StyledHeaderText>
                  )}
                </GridCol>
              </GridRow>
              <GridRow>
                <GridCol s="12" m="12" l="8" xl="8">
                  {ingress && (
                    <>
                      <StA.Ingress tag="p" variant={TextVariant.Ingress}>
                        {ingress}
                      </StA.Ingress>
                      <StA.HRLine />
                    </>
                  )}
                  {body ? (
                    <>
                      {keyInformation && (
                        <>
                          <Text tag="h2" variant={TextVariant.Header2}>
                            {keyInformation.title}
                          </Text>
                          {keyInformation.items.length > 0 && (
                            <S.StyledTextList maxWidth={512} variant="check">
                              {keyInformation.items.map((keyItem: string) => (
                                <TextListItem key={keyItem}>{keyItem}</TextListItem>
                              ))}
                            </S.StyledTextList>
                          )}
                        </>
                      )}
                      <StA.Body>
                        <BlockContent value={body} />
                      </StA.Body>
                    </>
                  ) : (
                    <LoaderContent />
                  )}
                  <MetaData meta={meta} metaDisplay={metaDisplay} />
                </GridCol>
              </GridRow>
            </GridCol>
          </GridRow>
          {relatedContent && relatedContent.length > 0 && <RelatedBenefits relatedContent={relatedContent} />}
          {!metaDisplay?.hideForm && (
            <GridRow>
              <GridCol s="12" m="12" l="8" xl="8">
                <FeedbackSurvey contentUrl={windowPath} />
              </GridCol>
            </GridRow>
          )}
        </Grid>
      </LayoutWithMainContent>
    );
  }
  return null;
};

export default LocalBenefitArticle;
